import "./style.scss";

import { Link } from "@mui/material";
import { AgentStage } from "@src/appV2/Agents";
import { openInAppBrowser } from "@src/lib/deprecatedCode";

import { favoritePolicyEnrolledMessage } from "./messageString";
import { getAccountRestrictedMessage } from "./utils";

interface MyAttendanceScoreProps {
  score: number;
  accountStatus: {
    status?: AgentStage | null;
    reEnrollmentDate?: string | undefined;
  };
  policyDetailUrl: string;
  tmz?: string;
}

export function MyAttendanceScoreContainer(props: MyAttendanceScoreProps) {
  const {
    score,
    accountStatus: { status, reEnrollmentDate },
    policyDetailUrl,
    tmz,
  } = props;
  const getScoreStyle = () => {
    if (score >= 70) {
      return "ratings-green";
    }
    if (score >= 30) {
      return "ratings-yellow";
    }
    if (score >= 1) {
      return "ratings-red";
    }
    return "ratings-black";
  };

  return (
    <div
      className="ratings-my-attendance-score-container"
      data-testid="my-attendance-score-container"
    >
      <div className={`ratings-score ${getScoreStyle()}`}>{score}</div>
      <div className="ratings-my-attendance-score-title">Attendance Score</div>
      <div className="ratings-my-attendance-score-description">
        <>
          {status === AgentStage.RESTRICTED
            ? getAccountRestrictedMessage(reEnrollmentDate, tmz)
            : favoritePolicyEnrolledMessage}{" "}
          <Link
            onClick={() => {
              openInAppBrowser(policyDetailUrl);
            }}
          >
            Learn more
          </Link>
          .
        </>
      </div>
    </div>
  );
}
