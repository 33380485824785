export const favoritePolicyEnrolledMessage =
  "An Attendance Score of 0 or below will result in account restriction. 100 is the max Attendance Score.";

export const accountRestrictedMessage =
  "Your account is restricted due to an Attendance Score of 0 or below. While restricted, you may only book shifts at workplaces where you are a favorite worker.";

export function getAccountRestrictedMessageWithEnrollmentDate(formattedDate: string): string {
  return `Your account is restricted due to an Attendance Score of 0 or below until ${formattedDate}. While restricted, you may only book shifts at workplaces where you are a
  favorite worker.`;
}
