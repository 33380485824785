import { formatDateTime } from "@clipboard-health/date-time";
import { isDefined } from "@clipboard-health/util-ts";

import { DEFAULT_TIMEZONE } from "./constants";
import {
  accountRestrictedMessage,
  getAccountRestrictedMessageWithEnrollmentDate,
} from "./messageString";

export function getAccountRestrictedMessage(reEnrollmentDate?: string, tmz?: string): string {
  return isDefined(reEnrollmentDate)
    ? getAccountRestrictedMessageWithEnrollmentDate(
        formatDateTime(reEnrollmentDate, { timeZone: tmz || DEFAULT_TIMEZONE })
      )
    : accountRestrictedMessage;
}
